import React from "react";
import { createRoot } from 'react-dom/client';
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";
import VideoBackground from "./components/VideoBackground/VideoBackground";
import video from "./assets/video/video.mp4"
import logo from "./assets/img/stadtkomfort-rund.png"
import "./styles.css";

function App() {
  return (
    <React.StrictMode>
    <div className="App">
      <VideoBackground
        vidSrc={video}
        muted={true}
        autoPlay={true}
        loop={true}
        playsInline={true}
        opacity={0.5} 
        backgroundColor={'#3c3c3c'} 
      >
      <div className="container">
      <img src={logo} className="logo" alt="Logo" />
        <h1>
          Demnächst
          <br />
          hier verfügbar
        </h1>
        <Timer />
        <Optin />
        <Preloader />
      </div>
      </VideoBackground>
    </div>
    </React.StrictMode>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
